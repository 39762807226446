
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























/* stylelint-disable
   selector-class-pattern,
   selector-max-combinators,
   selector-max-compound-selector,
   selector-max-compound-selectors */
.flexible > * {
  @include default-margin(0.6);

  &:last-child {
    margin-bottom: $spacing * 3;

    &.cpt-ctaText,
    &.cpt-ctaType {
      margin-bottom: 0;
    }

    @include mq(l) {
      margin-bottom: $spacing * 4;
    }
  }
}

.cpt-experts,
.cpt-expertstest {
  position: relative;
  overflow: hidden;
}

.flexible__hr {
  margin-right: var(--wrapper-padding);
  margin-left: var(--wrapper-padding);
  border-top: 0.1rem solid var(--c-border);

  @include mq(wrapper) {
    max-width: calc(var(--wrapper-max-width) - var(--wrapper-padding) * 2);
    margin-right: auto;
    margin-left: auto;
  }
}
